import io from "socket.io-client";

const urlSocket = 'desk.onkimia.com.mx'
//const urlSocket = "qa.desk.onkimia.com.mx";
//const urlSocket = "desk.aster.onkimia.com.mx";
//const urlSocket = "localhost:3000";
//const urlSocket = "localhost:4029";
//const urlSocket = "localhost:3005";
export const createTimeReal = async (status) => {
  io(urlSocket).emit("TREATMENT_EDIT", {
    status: status,
  });
};

export { urlSocket };
